/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary: #a2b077; 
$color-success: #7fd894; 
$color-info: #d9b5a3; 
$color-warning: #e892d7; 
$color-danger: #f88c71;

/* #GLOBAL IMPORTS
========================================================================== */
@import './src/scss/_imports/_theme-modules-import';

/* #Reset userselect
========================================================================== */
#myapp-3 {
	box-shadow: none !important;
}
#myapp-11 {
	box-shadow: 0 0 0 3px #000000;
}